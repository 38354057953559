import autosize from '../libs/autosize.min'


import './dropdown'

import FormValidation from '../libs/form-validation'



document.addEventListener('DOMContentLoaded', function(){


  autosize(document.querySelectorAll('textarea'))


  $('.js-select').niceSelect()

  class Carousel {
    constructor(carousel) {
      this.carousel = carousel
    }

    init() {
      if (this.carousel.length) {

        this.carousel.slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: false,
          prevArrow: '<svg class="slick-prev" width="20px" height="35px" fill="#313439" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.4 52.2"><path d="M.3 26.638c.1 1 .5 2 1.2 2.8l18 21c1.7 2.2 4.9 2.5 7 .8 2.2-1.7 2.5-4.9.8-7-.1-.1-.1-.2-.2-.2l-15.2-17.8 15.2-17.8c1.9-2 1.7-5.2-.3-7.1-2-1.9-5.2-1.7-7.1.3-.1.1-.1.2-.2.2l-18 21c-.9 1.1-1.3 2.4-1.2 3.8z"/></svg>',
          nextArrow: '<svg class="slick-next" width="20px" height="35px" fill="#313439" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.4 52.2"><path d="M28.3 25.638c-.1-1-.5-2-1.2-2.8l-18-21c-1.7-2.2-4.9-2.5-7-.8-2.2 1.7-2.5 4.9-.8 7 .1.1.1.2.2.2l15.2 17.8-15.1 17.8c-1.9 2-1.7 5.2.3 7.1 2 1.9 5.2 1.7 7.1-.3.1-.1.1-.2.2-.2l18-21c.8-1.1 1.3-2.4 1.1-3.8z"/></svg>',
          autoplaySpeed: 2000,
          responsive: [
            {
              breakpoint: 1120,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: true
              }
            },
            {
              breakpoint: 998,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: true
              }
            },
            {
              breakpoint: 925,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: true
              }
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true
              }
            }
          ]
        });

        this.carousel.on('breakpoint', function (event, slick, breakpoint) {
          var slidesCount  = slick.slideCount,
              slidesToSgow = slick.options.slidesToShow;

          if (slidesCount <= slidesToSgow) {
            slick.options.dots = false;
            slick.refresh();
          }
        });

      }
    }
    destroy() {
      if(this.carousel.length) {
        this.carousel.slick('unslick');
      } 
    }
  }

  const productCarousel = new Carousel($('.js-product-carousel'));

  productCarousel.init();

  const tabButton = $('.js-tab-button');

  if (tabButton.length) {

    tabButton.on('click', function() {

      productCarousel.destroy();

      const tabContainer = $(this).closest('.js-tab-container'),
        tabBody = tabContainer.find('.js-tab-body');

      tabButton.removeClass('active');
      $(this).addClass('active');
    
      tabBody.each((index, item) => {
        $(item).removeClass('active');
        
        if(index == $(this).data('index')) {
          $(item).addClass('active');
          productCarousel.init();
        }
      })
  
      return false;
    })
    
  }


  const reviewsCarousel = $('.js-reviews-carousel')

  if(reviewsCarousel.length) {
    reviewsCarousel.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      prevArrow: '<svg class="slick-prev" width="20px" height="33px" fill="#313439" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.4 52.2"><path d="M.3 26.638c.1 1 .5 2 1.2 2.8l18 21c1.7 2.2 4.9 2.5 7 .8 2.2-1.7 2.5-4.9.8-7-.1-.1-.1-.2-.2-.2l-15.2-17.8 15.2-17.8c1.9-2 1.7-5.2-.3-7.1-2-1.9-5.2-1.7-7.1.3-.1.1-.1.2-.2.2l-18 21c-.9 1.1-1.3 2.4-1.2 3.8z"/></svg>',
      nextArrow: '<svg class="slick-next" width="20px" height="33px" fill="#313439" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.4 52.2"><path d="M28.3 25.638c-.1-1-.5-2-1.2-2.8l-18-21c-1.7-2.2-4.9-2.5-7-.8-2.2 1.7-2.5 4.9-.8 7 .1.1.1.2.2.2l15.2 17.8-15.1 17.8c-1.9 2-1.7 5.2.3 7.1 2 1.9 5.2 1.7 7.1-.3.1-.1.1-.2.2-.2l18-21c.8-1.1 1.3-2.4 1.1-3.8z"/></svg>',
      dots: true,
      arrows: true,
      autoplaySpeed: 2000
    })
  }

  const brandsCarousel = $('.js-brands-carousel')

  if(brandsCarousel.length) {
    brandsCarousel.slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: false,
      dots: true,
      arrows: true,
      prevArrow: '<svg class="slick-prev" width="20px" height="33px" fill="#313439" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.4 52.2"><path d="M.3 26.638c.1 1 .5 2 1.2 2.8l18 21c1.7 2.2 4.9 2.5 7 .8 2.2-1.7 2.5-4.9.8-7-.1-.1-.1-.2-.2-.2l-15.2-17.8 15.2-17.8c1.9-2 1.7-5.2-.3-7.1-2-1.9-5.2-1.7-7.1.3-.1.1-.1.2-.2.2l-18 21c-.9 1.1-1.3 2.4-1.2 3.8z"/></svg>',
      nextArrow: '<svg class="slick-next" width="20px" height="33px" fill="#313439" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.4 52.2"><path d="M28.3 25.638c-.1-1-.5-2-1.2-2.8l-18-21c-1.7-2.2-4.9-2.5-7-.8-2.2 1.7-2.5 4.9-.8 7 .1.1.1.2.2.2l15.2 17.8-15.1 17.8c-1.9 2-1.7 5.2.3 7.1 2 1.9 5.2 1.7 7.1-.3.1-.1.1-.2.2-.2l18-21c.8-1.1 1.3-2.4 1.1-3.8z"/></svg>',
      autoplaySpeed: 2000,
      responsive: [
        {
          breakpoint: 1120,
          settings: {
            slidesToShow: 3, 
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    })
  }

  // first-screen home

  const car = $('.js-car');

  setInterval(() => {
    car.toggleClass('car-anim')
  }, 3000)


  $('.grid').masonry({  
    itemSelector: '.grid-item',
    horizontalOrder: true 
  })


  const productAboutReadMore = $('.js-product-about__read-more'),
        productAboutCharacterTable = $('.js-product-about__character-table')

  if(productAboutReadMore.length) {

    productAboutReadMore.on('click', function() {
      productAboutCharacterTable.toggleClass('open')

      if(productAboutCharacterTable.hasClass('open')) {
        $(this).find('span').text('свернуть обратно')
      } else {
        $(this).find('span').text('ЧИТАТЬ ДАЛЕЕ')
      }     

      return false
    })

  }


  const relatedCarousel = $('.js-related__carousel')

  if(relatedCarousel.length) {
    relatedCarousel.slick({
      slidesToShow: 2,
      slidesToScroll: 2,
      prevArrow: '<svg class="slick-prev" width="12px" height="21px" fill="#313439" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.4 52.2"><path d="M.3 26.638c.1 1 .5 2 1.2 2.8l18 21c1.7 2.2 4.9 2.5 7 .8 2.2-1.7 2.5-4.9.8-7-.1-.1-.1-.2-.2-.2l-15.2-17.8 15.2-17.8c1.9-2 1.7-5.2-.3-7.1-2-1.9-5.2-1.7-7.1.3-.1.1-.1.2-.2.2l-18 21c-.9 1.1-1.3 2.4-1.2 3.8z"/></svg>',
      nextArrow: '<svg class="slick-next" width="12px" height="21px" fill="#313439" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.4 52.2"><path d="M28.3 25.638c-.1-1-.5-2-1.2-2.8l-18-21c-1.7-2.2-4.9-2.5-7-.8-2.2 1.7-2.5 4.9-.8 7 .1.1.1.2.2.2l15.2 17.8-15.1 17.8c-1.9 2-1.7 5.2.3 7.1 2 1.9 5.2 1.7 7.1-.3.1-.1.1-.2.2-.2l18-21c.8-1.1 1.3-2.4 1.1-3.8z"/></svg>',
      dots: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1  
          }
        }
      ]
    })
  }


  const catalogMenu = $('.js-catalog-menu'),
        categoryList = $('.js-categories'),
        closeCategoryList = $('.js-categories-close')

  catalogMenu.on('click', function() {
    categoryList.addClass('open')
  })

  closeCategoryList.on('click', function() {
    categoryList.removeClass('open')
  })


  $('.category-menu__link').on('click', function() {
    categoryList.removeClass('open')
  })


  const filterMenu = $('.js-catalog-filter'),
        filterList = $('.js-categories-filter'),
        closefilterList  = $('.js-filter-close');

  filterMenu.on('click', function() {
    filterList.addClass('open')
  })

  closefilterList.on('click', function() {
    filterList.removeClass('open')
  })

  var $window = $(window);

  if (filterList.length) {
    var filterListOffset = filterList.offset().top,
        filterMenuOffet;

    if ($window.width() <= 991) {
      filterMenuOffet = filterMenu.offset().top + filterMenu.height();
    }

    $window.on('resize', function(){
      if ($window.width() <= 991) {
        filterMenuOffet = filterMenu.offset().top + filterMenu.height();
      } else {
        filterListOffset = filterList.offset().top;
      }
    });

    $window.on('scroll', function() {
      if ($(this).scrollTop() > filterListOffset + 60 && $(this).width() > 991) {
        filterList.addClass('sticky-filter');
      } else {
        filterList.removeClass('sticky-filter');
      }
      if ($(this).scrollTop() > filterMenuOffet && $(this).width() <= 991) {
        filterMenu.addClass('sticky-filter-menu');
      } else {
        filterMenu.removeClass('sticky-filter-menu');
      }
    });
  }



  // modals
  
  const authBtn = $('.js-auth'),
        registrationBtn = $('.js-registration'),
        restoreBtn = $('.js-restore')

  authBtn.on('click', function() {
    $.arcticmodal('close')
    $('#modal-auth').arcticmodal()
    return false
  })

  registrationBtn.on('click', function() {
    $.arcticmodal('close');
    $('#modal-registration').arcticmodal()
    return false
  })

  restoreBtn.on('click', function() {
    $.arcticmodal('close');
    $('#modal-restore').arcticmodal()
    return false
  })

  


  // modals end


  //filters

  //filters data
  let jsCountry = $('.js-country').parent().find('.js-drop-value').val();
  if(jsCountry) {
      $('.js-country').find('li').each(function(index,item) {
          if($(item).find('a').data('id') == jsCountry) {
              $('.js-country').parent().find('.js-drop-btn').text($(item).find('a').text());
          }
      });
  }

  let jsSource = $('.js-sources').parent().find('.js-drop-value').val();
  if(jsSource) {
      $('.js-sources').find('li').each(function(index,item) {
          if($(item).find('a').data('id') == jsSource) {
              $('.js-sources').parent().find('.js-drop-btn').text($(item).find('a').text());
          }
      });
  }

  function getFilters() {
      let priceStart = $('input[name="start-price"]').val() ? $('input[name="start-price"]').val() : '';
      let priceEnd   = $('input[name="end-price"]').val() ? $('input[name="end-price"]').val() : '';
      let geo        = $('.js-country').parent().find('.js-drop-value');
      let geoValue   = geo.val() ? geo.val() : '';

    if ((priceStart != '' || priceEnd != '') && geoValue == '') {
        geo.next().addClass('field-error');
        return false;
      }

      let search = $('.search__input').val() ? $('.search__input').val() : '';
      let source = $('.js-sources').parent().find('.js-drop-value').val() ? $('.js-sources').parent().find('.js-drop-value').val() : '';
  
      let order = $('.js-order').val();
      let limit = $('.js-pagination').val();
      if(!limit) {
          limit = 1;
      }


      let sex = "";
      if($('input[name="male"]').prop('checked')) {
          sex += "M";
      }
      if($('input[name="female"]').prop('checked')) {
          if(sex != "") {
              sex += ",";
          }
          sex += "F";
      }
      if($('input[name="unisex"]').prop('checked')) {
          if(sex != "") {
              sex += ",";
          }
          sex += "U";
      }

      return {
        limit : limit,
        order : order,
        search : search,
        priceStart : priceStart,
        priceEnd : priceEnd,
        geo : geoValue,
        source : source,
        sex : sex,
      } 
      
  }

  $('.js-pagination').on('change',function() {
      let tmp = getFilters();
      location.href = "/catalog/?limit=" + tmp.limit + "&sort=" + tmp.order + "&filter[name]=" + tmp.search + "&filter[price_from]=" + tmp.priceStart + "&filter[price_till]=" + tmp.priceEnd + "&filter[country]=" + tmp.geo + "&filter[source]=" + tmp.source + "&filter[gender]=" + tmp.sex;
       
  });

  $('.js-order').on('change',function() {
    let tmp = getFilters();
    location.href = "/catalog/?limit=" + tmp.limit + "&sort=" + tmp.order + "&filter[name]=" + tmp.search + "&filter[price_from]=" + tmp.priceStart + "&filter[price_till]=" + tmp.priceEnd + "&filter[country]=" + tmp.geo + "&filter[source]=" + tmp.source + "&filter[gender]=" + tmp.sex;
     
  });

  $('.js-filter-send').on('click',function() {
    let tmp = getFilters();
  
    if (tmp == false) {
      return false;
    } else {
      location.href = "/catalog/?limit=" + tmp.limit + "&sort=" + tmp.order + "&filter[name]=" + tmp.search + "&filter[price_from]=" + tmp.priceStart + "&filter[price_till]=" + tmp.priceEnd + "&filter[country]=" + tmp.geo + "&filter[source]=" + tmp.source + "&filter[gender]=" + tmp.sex;
    }
     
  });

  //filters end


  // btn load more
  const btnLoadMore = $('.js-btn-load-more')
  const productsContainer = $('.js-products-table__body') 

  btnLoadMore.on('click', function() {
 
        
        $(this).addClass('btn-load-more_loaded') 
        
        let filtersData = getFilters();
        filtersData.page = $(this).data('page') ? $(this).data('page') : 1;
        filtersData._token = $('input[name="_token"]').val();
        filtersData.is_ajax = 1;

        $.ajax({
            url: '/catalog',
            method: 'POST',
            data: filtersData
        }).done(data => {

          $(this).removeClass('btn-load-more_loaded');

          if(data.status == "success" && data.html) {
              $('.js-products-table__body').append(data.html);
              if(data.page) {
                btnLoadMore.data('page', data.page);
              }
              if(!data.needReadMore) {
                btnLoadMore.parent().remove();
              }
          }
            
            
        })

      return false
  })


  $(".js-to-form").on("click", function (event) {    
    event.preventDefault()    
    var id  = $(this).attr('href'),
        top = $(id).offset().top;
    $('body,html').animate({scrollTop: top}, 1500)
  });




  $(".js-auth-form").on('submit', function() {
    var form = new FormValidation({
      form:                 this,
      messageBlock:         '.js-form-notification',
      messageSuccess:       '',
      messageEmpty:         'Заполните все поля!',
      messageInvalidEmail:  'Введите коректный email!',
      messageNotEqualEmail: ''
    });

    if (form.formIsValid()) {
      form.messageSuccess();
      form.clearFields();
      $.arcticmodal('close');
    } else {
      console.log('invalid');
    }

    return false;
  });

  $(".js-registration-form").on('submit', function() {
    var form = new FormValidation({
      form:                 this,
      messageBlock:         '.js-form-notification',
      messageSuccess:       '',
      messageEmpty:         'Заполните все поля!',
      messageInvalidEmail:  'Введите коректный email!',
      messageNotEqualEmail: '',
      messageNotEqualPass:  'Пароли не совпадают'
    });

    if (form.formIsValid()) {
      form.messageSuccess();
      form.clearFields();
      $.arcticmodal('close');
    } else {
      console.log('invalid');
    }

    return false;
  });

  $(".js-restore-form").on('submit', function() {
    var form = new FormValidation({
      form:                 this,
      messageBlock:         '.js-form-notification',
      messageSuccess:       '',
      messageEmpty:         'Заполните поле!',
      messageInvalidEmail:  'Введите коректный email!',
      messageNotEqualEmail: ''
    });

    if (form.formIsValid()) {
      form.messageSuccess();
      form.clearFields();
      $.arcticmodal('close');
    } else {
      console.log('invalid');
    }

    return false;
  });


  // LEGACY

 
  const legacy = () => {
    
    var header = $('.js-header');   

    $('.js-hamburger').on('click', function(){
        $(this).toggleClass('is-active');
        header.toggleClass('open');
        if ($(this).hasClass('is-active')) {
            $("html, body").css({'overflow':'hidden','height':'100%'});
            $("html").css({'overflow-y':'scroll'});
        } else {
            $("html, body").css({'overflow':'visible','height':'auto'});
        }
    });



    //  modal windows
    $('.js-modal-link').on('click',function(){
        var t = $(this).attr('modal-taret');
        $.arcticmodal('close');
        $('.' + t).arcticmodal({
            beforeOpen: function(data, el) {
                $('body').addClass('modal-open');
            },
            afterClose: function(data, el) {
                $('body').removeClass('modal-open');
            }
        });
        return false;
    });

    // create owl carousel for blog and article pages
    function createOwlCarousel(item, windowWidth) {
        if( $window.width() <= windowWidth && item ) {
            item.owlCarousel({
                items: 1,
                loop: false,
                nav: true,
                rewind: true,
                dots: false,
                center: true
            });
        }
    }

    var $relatedPosts = $('.js-related-posts'),
        windowWidth1 = 792;
    createOwlCarousel($relatedPosts, windowWidth1);

    var $top3OfferTabs = $('.js_tab_content'),
        windowWidth2  = 940;
    createOwlCarousel($top3OfferTabs, windowWidth2);

    $window.on('resize', function() {
        createOwlCarousel($relatedPosts, windowWidth1);
        createOwlCarousel($top3OfferTabs, windowWidth2);
        var windowWidth = $window.width();
        if (windowWidth > windowWidth2 && $top3OfferTabs.hasClass('owl-loaded')) {
            $top3OfferTabs.trigger('destroy.owl.carousel');
        }
        if (windowWidth > windowWidth1 && $relatedPosts.hasClass('owl-loaded')) {
            $relatedPosts.trigger('destroy.owl.carousel');
        }
    });
    // end owl carousel for blog and article pages


    // article post raiting

    function makeStarsActive(stars, currentStar, className) {
        var currentStraIndex = currentStar.index(),
            currentStraValue = currentStar.data('value');

        stars.removeClass(className);
        
        for (var i = 0; i <= currentStraIndex; i++) {
            stars.eq(i).addClass(className);
        }
    };

    var $postRaiting = $('.js-raitings-list');

    if ($postRaiting.length > 0) {
        var $raitingStars = $postRaiting.find('.star');

        $raitingStars.on('click', function() {
            makeStarsActive($raitingStars, $(this), 'active');
        });

        $raitingStars.hover(function() {
            makeStarsActive($raitingStars, $(this),'star-selected');
        },
        function() {
            $raitingStars.removeClass('star-selected');
        });
    }

    // article post raiting end



    var formComment = $('.js-form'),
        pattern = /^([a-z0-9_\.-])+@[a-z0-9-]+\.([a-z]{2,4}\.)?[a-z]{2,4}$/i;

    if(formComment.length) {
      formComment.on('submit', function(ev) {

          ev.preventDefault();

          var email = $(this).find('input[type=email]'),
              resultMessage = $(this).find('.js-result-message');

          if( email.val() == '' ) {
              resultMessage            
                  .addClass('error')
                  .text('Укажите email');

          } else if( !pattern.test(email.val()) ) {
              resultMessage
                  .addClass('error')
                  .text('Введите правильный email');
          } else {
              $(this).find('input').not('input[type=submit]').val('');
              $(this).find('textarea').val('');

              resultMessage
                  .removeClass('error')                
                  .text(' ');
          }
      })
    }




      // article modal window validate
      var modalType1Forms = document.querySelectorAll('.js_modal_type_1 .js_modal_form');
      if (modalType1Forms) {
          modalType1Forms.forEach(function(formItem) {
              formItem.addEventListener('submit', function(event) {

                  event = event || window.event;

                  if (event.preventDefault) {
                      event.preventDefault();
                  } else {
                      event.returnValue = false;
                  }

                  var email  = this.querySelector('input:required'),
                      notify = this.querySelector('.js_form_notify');
                  
                  notify.innerHTML = '';

                  if (email.value == '') {
                      notify.innerHTML = 'Укажите email';
                  } else if (!validateEmail(email.value)) {
                      notify.innerHTML = 'Введите правильный email';
                  } else {
                      email.value = '';
                  }
              });
          });
      }

      // blog tabs
      var $tabs = $('.js_tabs_wrapper');
      if ($tabs.length > 0) {
          var $tabs_titles   = $tabs.find('.js_tab_title'),
              $tabs_contents = $tabs.find('.js_tab_content');

          $tabs_contents.not(':first').hide();
          $tabs_titles.on('click', function() {
              $tabs_titles.removeClass('active');
              $(this).addClass('active');
              $tabs_contents.hide();
              $tabs_contents.eq($(this).index()).show();
          });
      }




    function validateEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

  }


  legacy()


})