;var FormValidation = function(args) {
    var form = args.form,
        msgBlock         = args.form.querySelector(args.messageBlock),
        msgEmpty         = args.messageEmpty,
        msgInvalidEmail  = args.messageInvalidEmail,
        msgNotEqualEmail = args.messageNotEqualEmail,
        msgSuccess       = args.messageSuccess,
        msgNotEqualPass  = args.messageNotEqualPass,
        inputs           = form.querySelectorAll('input, textarea'),
        emails           = form.querySelectorAll('input[type=email]'),
        passwords        = form.querySelectorAll('input[type=password]'),
        isValid          = true,
        msgCurrentText   = '',
        patternMail      = /^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@([a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\.)*(aero|arpa|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|[a-z][a-z])$/;

    if (inputs.length) {

        Array.prototype.forEach.call(inputs, function(item) {
            if (item.hasAttribute('required')) {
                if (item.value == '') {
                    item.classList.add('is-invalid');
                    isValid = false;
                } else {
                    item.classList.remove('is-invalid');
                }
            }
        });
        if (!isValid) msgCurrentText = msgEmpty;
    };

    if (isValid && emails.length > 1) {
        let userEmail = '',
            confirmEmail = '';

        Array.prototype.forEach.call(emails, function(emailItem) {
            if (emailItem.getAttribute('name') == 'email') {
                userEmail = emailItem;
            }
            if (emailItem.getAttribute('name') == 'confirm-email') {
                confirmEmail = emailItem;
            }
        });

        if (userEmail.value != confirmEmail.value) {
            userEmail.classList.add('is-invalid');
            confirmEmail.classList.add('is-invalid');
            isValid = false;
            msgCurrentText = msgNotEqualEmail;
        }
    }

    if (isValid && emails.length) {
        Array.prototype.forEach.call(emails, function(email) {
            if (!patternMail.test(email.value)) {
                email.classList.add('is-invalid');
                isValid = false;
            } else {
                email.classList.remove('is-invalid');
            }
        });
        if (!isValid) msgCurrentText = msgInvalidEmail;
    };

    if (isValid && passwords.length > 1) {
        let userPass = '',
            confirmPass = '';

        Array.prototype.forEach.call(passwords, function(passwordItem) {
            if (passwordItem.getAttribute('name') == 'password') {
                userPass = passwordItem;
            }
            if (passwordItem.getAttribute('name') == 'confirm-password') {
                confirmPass = passwordItem;
            }
        });

        if (userPass.value != confirmPass.value) {
            userPass.classList.add('is-invalid');
            confirmPass.classList.add('is-invalid');
            isValid = false;
            msgCurrentText = msgNotEqualPass;
        }
    }

    if (msgBlock && !isValid) {
        msgBlock.classList.remove('result-success');
        msgBlock.classList.add('text-danger');
        msgBlock.innerText = msgCurrentText;
    }

    this.messageSuccess = function() {
        if (msgBlock) {
            msgBlock.classList.remove('text-danger');
            msgBlock.classList.add('text-success');
            msgBlock.textContent = msgSuccess;
        }
    };

    this.clearFields = function() {
        if(inputs.length) {
            [].forEach.call(inputs, function(item) {
                if (item.type != 'submit') item.value = '';
            });
        }
    };

    this.formIsValid = function() {
        return isValid;
    };
};

export default FormValidation