const dropdown = () => {

    const dropBtn = $('.js-drop-btn')

    dropBtn.on('click', function() {
        $(this).toggleClass('open')
    })

    const container = $('.js-drop-container')

    container.on('click', '.js-drop-list ul li a', function() {
        const container = $(this).closest('.js-drop-container')
        const current = container.find('.js-drop-btn')
        const input = container.find('.js-drop-value')
        const content = $(this).html()
        
		let value = $(this).text()
		if($(this).data('id')) {
			value = $(this).data('id');
		}
			

        current.html(content)
        input.val(value)
    })

    $(document).on('click', function(event) {
        const { target } = event
        const container = $(target).closest('.js-drop-btn')
         if( !container.length ) {
            dropBtn.removeClass('open')                        
        }
    })

}

dropdown()